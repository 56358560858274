
import { mapMutations } from 'vuex';
export default {
    methods: {
        ...mapMutations('short_article', [
            'toggleOpenPostBlock',
            'setPostMode'
        ]),
        createNewShortArticle() {
            this.toggleOpenPostBlock();
            this.setPostMode('create');
            this.$router.push('/')
        },
    },

}
