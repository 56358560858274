
import axios from "axios";
import { mapMutations } from 'vuex';
export default {
    props: {
        block_name: String
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        block_display_name() {
            return this.$store.state.pcard.block_display_name;
        }
    },
    methods: {
        ...mapMutations("pcard", [
            "toggleUnblockCheckBox"
        ]),
        async unblockMember() {
            const API_url = "https://www.pixnet.net/mainpage/api/channel/setMemberRelation"
            let bodyFormData = new FormData();
            bodyFormData.append('member_token', this.member_token)
            bodyFormData.append('member_uniqid', this.block_display_name.uniqid)
            bodyFormData.append('type', 'block')
            bodyFormData.append('action', 0)
            const req = await axios({
                method: 'post',
                url: API_url,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            }).catch((e)=> {
                console.log(e)
            })
            if(this.is_login && this.member_token!= null) {
                if(req.status === 200 && !req.error) {
                    this.$parent.changeTabAndReloadData();
                    this.toggleUnblockCheckBox();
                } else {
                    return Promise.reject(req);
                }
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        }
    },
}
