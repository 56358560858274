
import { mapMutations } from 'vuex'; 
export default {
    name: "user_point_intro",
    methods: {
        ...mapMutations("pcard", [
            "toggleUserPointIntro",
        ]),
    },
}
