
import { mapMutations } from 'vuex'; 
import { USER_RANKS } from '~/utils/user_point';
import { separateNumber } from '~/utils/number';

export default {
    name: "user_point_rank_detail",
    computed: {
        total_point() {
            return this.$store.state.pcard.user_total_point;
        },
        rank() {
            return this.$store.state.pcard.user_point_rank_detail;
        },
        rank_data() {
            if (this.rank) {
                return USER_RANKS[this.rank] ?? null;
            }
            return null;
        },
        last_rank_point() {
            if (this.rank && this.rank > 1) {
                return USER_RANKS[this.rank - 1]?.point ?? 0;
            }
            return 0;
        },
        rank_marks() {
            if (this.rank_data === null) return null;
            if (this.total_point < this.last_rank_point) return 0;
            if (this.total_point >= this.rank_data.point) return 5;
            const eachStage = (this.rank_data.point - this.last_rank_point) / 5;
            const mark = Math.floor((this.total_point - this.last_rank_point) / eachStage);
            return mark > 5 ? 5 : mark;
        },
    },
    methods: {
        ...mapMutations("pcard", [
            "toggleUserPointRankDetail",
        ]),
        separateNumber,
    },
}
