
import axios from "axios";
import { mapActions, mapMutations } from 'vuex';
import PcardArticleCard from "~/components/child/article/PcardArticleCard.vue";
import PcardShortArticleCard from "~/components/child/article/PcardShortArticleCard.vue";
import ShortArticleReport from '~/components/child/article/lightbox/ShortArticleReport.vue';
import ArticleReportResponse from '~/components/child/article/lightbox/ArticleReportResponse.vue';
import ArticleShare from '~/components/child/article/lightbox/ArticleShare.vue';
import PcardShortArticleCheckToDelete from '~/components/child/article/lightbox/PcardShortArticleCheckToDelete.vue';
import ToastContainer from "~/components/child/lightbox/ToastContainer.vue";
import PcardFollowCard from "~/components/child/pcard/pcardFollowCard.vue";
import NoArticleCard from "~/components/child/pcard/NoArticleStatus.vue";
import ArticleReport from "~/components/child/article/lightbox/ArticleReport.vue";
import PcardBlockCard from "~/components/child/pcard/pcardBlockCard.vue";
import NoRelationCard from "~/components/child/pcard/NoRelationStatus.vue";
import ExecuteBlog from "~/components/child/pcard/ExecuteBlog.vue";
import ExecuteShortArticle from "~/components/child/pcard/ExecuteShortArticle.vue";
import BlockMember from "~/components/child/pcard/BlockMember.vue";
import MemberReport from "~/components/child/pcard/MemberReport.vue";
import IsBlockMember from "~/components/child/pcard/IsBlockMember.vue";
import CheckToUnFollow from "~/components/child/pcard/CheckToUnFollow.vue";
import CheckToUnBlockMember from "~/components/child/pcard/CheckToUnBlockMember.vue";
import UserPointRank from "~/components/child/pcard/UserPointRank.vue";
import UserPointIntro from "~/components/child/pcard/modal/UserPointIntro.vue";
import UserPointRankDetail from "~/components/child/pcard/modal/UserPointRankDetail.vue";
import PostAndEditShortArticleModule from "~/components/child/post/PostAndEditShortArticleModule.vue";
import FalconAd from "~/components/common/widget/FalconAd.vue";
import { TITLE, DESCRIPTION, KEYWORDS, OG_IMAGE } from '~/utils/const';

export default {
    head() {
        return {
            title: this.og_title,
            meta: [
                { hid: "description", name: "description", content: this.og_description },
                { hid: "og:description", name: "og:description", property: "og:description", content: this.og_description },
                { hid: "twitter:description", name: "twitter:description", property: "twitter:description", content: this.og_description },
                { hid: "keywords", name: "keywords", content: KEYWORDS },
                { hid: "og:title", name: "og:title", property: "og:title", content: this.og_title },
                { property: "og:type", property: "og:type", content: "website" },
                { property: "og:image", property: "og:image", content: this.og_image },
                { property: "og:image:secure_url", property: "og:image:secure_url", content: this.og_image },
                { property: "twitter:image", property: "twitter:image", content: this.og_image },
                { property: "image", name: "image", itemprop: "image", content: this.og_image },
            ],
            link: [
                { rel: 'canonical', href: 'https://www.pixnet.net/pcard/' + this.profile_id, key: "canonical" }
            ],
            script: [
                { async: true, src: "https://www.googletagmanager.com/gtag/js?id=G-ZXCL2XMGZC" },
                { type: 'text/javascript', src: "/js/pa_init_pcard.js", ssr: false },
            ]
        };
    },
    jsonld() {
        return {
            "@context": " https://schema.org/",
            "@graph": [
                {
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "url": "https://www.pixnet.net",
                    "logo": OG_IMAGE,
                    "name": "痞客邦",
                    "@id": "https://www.pixnet.net/#organization",
                    "description": DESCRIPTION,
                    "address": "",
                    "brand": {
                        "@type": "Brand",
                        "logo": OG_IMAGE
                    },
                    "sameAs": "https://www.facebook.com/pixnet.net"
                },
                {
                    "@context": "https://schema.org/",
                    "@type": "BreadcrumbList",
                    "itemListElement": [
                        {
                            "@type": "ListItem",
                            "position": 1,
                            "name": TITLE,
                            "item": " https://www.pixnet.net"
                        }
                    ]
                }
            ]
        };
    },
    components: {
        PcardArticleCard,
        PcardShortArticleCard,
        ShortArticleReport,
        ArticleReportResponse,
        ArticleShare,
        PcardShortArticleCheckToDelete,
        ToastContainer,
        PcardFollowCard,
        NoArticleCard,
        ArticleReport,
        PcardBlockCard,
        NoRelationCard,
        ExecuteBlog,
        ExecuteShortArticle,
        BlockMember,
        MemberReport,
        IsBlockMember,
        CheckToUnFollow,
        CheckToUnBlockMember,
        UserPointRank,
        UserPointIntro,
        UserPointRankDetail,
        PostAndEditShortArticleModule,
        FalconAd,
    },
    data() {
        return {
            showAnimation: false,
            list_type_menu_id: 1,
            article_page: 2,
            short_article_page: 2,
            fans_page: 2,
            follow_page: 2,
            block_page: 2,
            relation_is_loading: false,
            accept_img_type: 'image/png, image/jpeg, image/gif, image/heic',
            is_follow: this.profile_data?.followed ?? false,
            blog_category_list: {
                '攝影寫真': { img: 'img_photo', link: '/channel/3c' },
                '休閒旅遊': { img: 'img_travel', link: '/channel/travel' },
                '美食情報': { img: 'img_food', link: '/channel/food' },
                '心情日記': { img: 'img_feeling', link: '/channel/body-mind-spirit' },
                '星座命理': { img: 'img_star', link: '/channel/body-mind-spirit' },
                '收藏嗜好': { img: 'img_save', link: '/channel/home' },
                '生活綜合': { img: 'img_life', link: '/channel/home' },
                '職場甘苦': { img: 'img_career', link: '/channel/career' },
                '財經政論': { img: 'img_career', link: '/channel/career' },
                '時尚美妝': { img: 'img_female', link: '/channel/beauty' },
                '數位生活': { img: 'img_digital', link: '/channel/3c' },
                '電玩動漫': { img: 'img_play', link: '/channel/3c' },
                '視覺設計': { img: 'img_art', link: '/channel/home' },
                '圖文創作': { img: 'img_art', link: '/channel/3c' },
                '居家設計': { img: 'img_home', link: '/channel/home' },
                '婚姻育兒': { img: 'img_marry', link: '/channel/family' },
                '寵物日記': { img: 'img_daily', link: '/channel/pet' },
                '運動體育': { img: 'img_gym', link: '/channel/health' },
                '藝文情報': { img: 'img_art-1', link: '/channel/movie' },
                '偶像明星': { img: 'img_art-1', link: '/channel/movie' },
                '視聽娛樂': { img: 'img_movie', link: '/channel/movie' },
                '社團組織': { img: 'img_organize', link: '/channel/career' },
                '不設分類': { img: 'img_other', link: '/' }
            },
            block_menu_status: false,
        };
    },
    computed: {
        profile_id() {
            return this.$route.params.id;
        },
        user_id() {
            return this.member_data?.member_uniqid;
        },
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        member_data() {
            return this.$store.state.login.member_data;
        },
        profile_data() {
            return this.$store.state.pcard.profile_data;
        },
        user_article() {
            return this.$store.state.pcard.user_article;
        },
        article_page_count() {
            return this.$store.state.pcard.user_article_count;
        },
        user_short_article() {
            return this.$store.state.pcard.user_short_article;
        },
        short_article_page_count() {
            return this.$store.state.pcard.user_short_article_count;
        },
        odd_column_short_article_data() {
            return this.user_short_article.filter((v, i) => !(i % 2));
        },
        even_column_short_article_data() {
            return this.user_short_article.filter((v, i) => i % 2);
        },
        is_user_mode() {
            if (this.profile_id.length == 18 && this.profile_id == this.user_id) {
                return true;
            }
            else if (this.profile_id == this.member_data?.member_name) {
                return true;
            }
            else {
                return false;
            }
        },
        user_relation() {
            return this.$store.state.pcard.user_relation_list;
        },
        fans_count() {
            return this.$store.state.pcard.user_fans_count;
        },
        follow_count() {
            return this.$store.state.pcard.user_follow_count;
        },
        block_count() {
            return this.$store.state.pcard.user_block_count;
        },
        tab_id() {
            return this.$store.state.pcard.tab_id;
        },
        tab_left() {
            return this.$store.state.pcard.tab_highlight_style.left;
        },
        tab_width() {
            return this.$store.state.pcard.tab_highlight_style.width;
        },
        fan_tab_id() {
            return this.$store.state.pcard.fan_tab_id;
        },
        fan_tab_left() {
            return this.$store.state.pcard.fan_tab_highlight_style.left;
        },
        fan_tab_width() {
            return this.$store.state.pcard.fan_tab_highlight_style.width;
        },
        window_width() {
            return this.$store.state.pcard.window_width;
        },
        fans_page_max() {
            return this.profile_data.counter?.fans_count ? Math.ceil(this.profile_data.counter?.fans_count / 20) : 0;
        },
        follow_page_max() {
            return this.profile_data.counter?.follow_count ? Math.ceil(this.profile_data.counter?.follow_count / 20) : 0;
        },
        block_page_max() {
            return Math.ceil(this.block_count / 20);
        },
        app_direct_status() {
            return this.$store.state.lightbox.app_direct;
        },
        pcard_fans_list_status() {
            return this.$store.state.pcard.pcard_fans_list_status;
        },
        user_blog_category() {
            return this.$store.state.pcard.user_blog_category;
        },
        user_short_article_cursor() {
            return this.$store.state.pcard.user_short_article_cursor;
        },
        article_loading_status() {
            return this.$store.state.pcard.article_is_loading;
        },
        block_check_box() {
            return this.$store.state.pcard.block_check_box;
        },
        unfollow_check_box() {
            return this.$store.state.pcard.unfollow_check_box;
        },
        unblock_check_box() {
            return this.$store.state.pcard.unblock_check_box;
        },
        open_block() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            return urlParams.has('open_block');
        },
        title_name() {
            if (this.profile_data.displayname != undefined) {
                return this.profile_data.displayname;
            } else {
                return this.profile_id;
            }
        },
        og_image() {
            if (this.profile_data.avatar) {
                return this.profile_data.avatar;
            } else {
                return OG_IMAGE;
            }
        },
        og_title() {
            return `痞客邦-${this.title_name}的名片頁`;
        },
        og_description() {
            if (this.profile_data.blog?.introduction) {
                return this.profile_data.blog?.introduction;
            } else {
                return "";
            }
        },
        open_post_block() {
            return this.$store.state.short_article.open_post_block;
        },
        safari_InnerHeight() {
            return this.$store.state.safariInnerHeight;
        },
        user_point_intro_status() {
            return this.$store.state.pcard.user_point_intro_status;
        },
        user_point_rank_detail() {
            return this.$store.state.pcard.user_point_rank_detail;
        },
    },
    methods: {
        ...mapActions("pcard", [
            "getProfileData",
            "getUserArticle",
            "getUserShortArticle",
            "getUserBlockedList",
            "getMoreUserArticle",
            "getMoreUserShortArticle",
            "getUserArticleCount",
            "getRelationData",
            "getMoreRelationData",
            "getUserBlogCategory",
            "getRelationDataAndReset",
            "getUserPoint",
        ]),
        ...mapMutations("pcard", [
            "updateTabId",
            "updateTabLeft",
            "updateTabWidth",
            "updateFanTabId",
            "updateFanTabLeft",
            "updateFanTabWidth",
            "updateWindowWidth",
            "togglePcardFansList",
            "toggleBlockCheckBox",
            "toggleUnblockCheckBox"
        ]),
        ...mapMutations('lightbox', [
            'setViewData',
        ]),
        ...mapMutations('report', [
            'toggleReportMember',
        ]),
        rebootPixAd() {
            if (window.PIXFalcon) {
                var pixAd;
                pixAd = new PIXFalcon();
                pixAd.placeAds(document);
            }
        },
        toggleFollowerList() {
            this.changeTabAndReloadData();
            this.togglePcardFansList();
        },
        openFollowerListAndActiveFanTab(id) {
            this.toggleFollowerList();
            this.activeFanTab(id);
        },
        sildeToArticlePart() {
            if (window.innerWidth < 500) {
                const index_main_tab = document.querySelector('.pcard__left');
                const instance = index_main_tab.getBoundingClientRect().height + 82 ;
                window.scrollTo({
                    top: instance
                });
            }
        },
        activeTab(id) {
            if (this.tab_id != id) {
                this.sildeToArticlePart()
                this.setTabHighLighter(id);
                if (id == 1) {
                    this.paClickMenu('文章卡片');
                } else if (id == 2) {
                    this.paClickMenu('短貼文卡片');
                }
            }
        },
        countTabLeft() {
            return this.$refs.pcard_tab.getBoundingClientRect().left;
        },
        initTabHighLighter(id) {
            const width = this.$refs.pcard_tab.children[id - 1].clientWidth;
            const left = this.$refs.pcard_tab.children[id - 1].getBoundingClientRect().left;
            const tab_left = this.countTabLeft();
            this.updateTabLeft(left - tab_left);
            this.updateTabWidth(width);
        },
        resizeTabHighLighter(id) {
            setTimeout(() => {
                if (this.$refs.pcard_tab.children[id - 1] != undefined) {
                    const width = this.$refs.pcard_tab.children[id - 1].clientWidth;
                    const left = this.$refs.pcard_tab.children[id - 1].getBoundingClientRect().left;
                    const tab_left = this.countTabLeft();
                    this.updateTabLeft(left - tab_left);
                    this.updateTabWidth(width);
                }
            }, 200);
        },
        setTabHighLighter(id) {
            const width = this.$refs.pcard_tab.children[id - 1].clientWidth;
            const left = this.$refs.pcard_tab.children[id - 1].getBoundingClientRect().left;
            const tab_left = this.countTabLeft();
            this.updateTabLeft(left - tab_left);
            this.updateTabWidth(width);
            this.updateTabId(id);
        },
        changeTabAndReloadData() {
            // 封鎖名單暫時只有顯示，不需要reload
            const follow_params = {
                member_uniqid: this.profile_data?.uniqid,
                member_token: this.member_token,
                type: 'follow'
            };
            const fans_params = {
                member_uniqid: this.profile_data?.uniqid,
                member_token: this.member_token,
                type: 'fans'
            };
            const block_params = {
                member_uniqid: this.profile_data?.uniqid,
                member_token: this.member_token,
                type: 'block'
            }
            this.getRelationDataAndReset(follow_params);
            this.getRelationDataAndReset(fans_params);
            this.getRelationDataAndReset(block_params)
        },
        async activeFanTab(id) {
            if (this.fan_tab_id != id) {
                this.setFanTabHighLighter(id);
            }
            if (id == 1) {
                this.paClickMenu('粉絲數');
            } else if (id == 2) {
                this.paClickMenu('關注數');
            }
        },
        countFanTabLeft() {
            return this.$refs.pcard_fan_tab.getBoundingClientRect().left;
        },
        initFanTabHighLighter(id) {
            const width = this.$refs.pcard_fan_tab.children[id - 1].clientWidth;
            const left = this.$refs.pcard_fan_tab.children[id - 1].getBoundingClientRect().left;
            const tab_left = this.countFanTabLeft();
            this.updateFanTabLeft(left - tab_left);
            this.updateFanTabWidth(width);
        },
        resizeFanTabHighLighter(id) {
            setTimeout(() => {
                if (this.$refs.pcard_fan_tab.children[id - 1] != undefined) {
                    const width = this.$refs.pcard_fan_tab.children[id - 1].clientWidth;
                    const left = this.$refs.pcard_fan_tab.children[id - 1].getBoundingClientRect().left;
                    const tab_left = this.countFanTabLeft();
                    this.updateFanTabLeft(left - tab_left);
                    this.updateFanTabWidth(width);
                }
            }, 200);
        },
        setFanTabHighLighter(id) {
            const width = this.$refs.pcard_fan_tab.children[id - 1].clientWidth;
            const left = this.$refs.pcard_fan_tab.children[id - 1].getBoundingClientRect().left;
            const tab_left = this.countFanTabLeft();
            this.updateFanTabLeft(left - tab_left);
            this.updateFanTabWidth(width);
            this.updateFanTabId(id);
        },
        setShowAnimation() {
            setTimeout(() => {
                this.showAnimation = true;
            }, 500);
        },
        setShowAnimation() {
            setTimeout(() => {
                this.showAnimation = true;
            }, 500);
        },
        setWindowWidth() {
            this.updateWindowWidth(window.innerWidth);
        },
        executeWhenResize(e) {
            this.setWindowWidth();
            this.resizeTabHighLighter(this.tab_id);
            this.resizeFanTabHighLighter(this.fan_tab_id);
        },
        openToast() {
            this.$refs.toast_box.openToastElement();
        },
        followBlogger(id) {
            if (this.is_login && this.member_token != null) {
                const reqInstance = axios.create({
                    headers: {
                        Authorization: this.member_token
                    }
                });
                const API_url = "https://www.pixnet.net/api/v1/member/following/" + id;
                const req = reqInstance.put(API_url);
                req.then((e) => {
                    if (!e.data.error && e.status === 200) {
                        this.is_follow = true;
                        this.setViewData("follow");
                        this.openToast();
                    }
                })
                    .catch((e) => {
                        console.log(e)
                    })
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        unfollowBlogger(id) {
            if (this.is_login && this.member_token != null) {
                const reqInstance = axios.create({
                    headers: {
                        Authorization: this.member_token
                    }
                });
                const API_url = "https://www.pixnet.net/api/v1/member/following/" + id;
                const req = reqInstance.delete(API_url);
                req.then((e) => {
                    if (!e.data.error && e.status === 200) {
                        this.is_follow = false;
                        this.setViewData("unfollow");
                        this.openToast();
                    }
                })
                    .catch((e) => {
                        console.log(e)
                    })
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        toggleFollowBlogger(status, id) {
            if (status) {
                this.paClickFollowOrEditBtn('已關注');
                this.unfollowBlogger(id);
            } else {
                this.paClickFollowOrEditBtn('關注');
                this.followBlogger(id);
            }
        },
        async moreArticle() {
            this.paClickMoreArticle();
            const params = {
                member_uniqid: this.profile_data?.uniqid,
                page: this.article_page,
                member_token: this.member_token
            }
            await this.getMoreUserArticle(params);
            this.article_page = this.article_page + 1;
        },
        async moreShortArticle() {
            this.paClickMoreShortArticle();
            const params = {
                member_uniqid: this.profile_data.uniqid,
                cursor: this.user_short_article_cursor,
            }
            await this.getMoreUserShortArticle(params);
            this.short_article_page = this.short_article_page + 1;
        },
        initTabAll() {
            window.addEventListener("resize", this.executeWhenResize);
            this.initTabHighLighter(this.tab_id);
            this.initFanTabHighLighter(this.fan_tab_id);
            this.setShowAnimation();
            this.setWindowWidth();
            this.countTabLeft();
        },
        async toGetMoreRelaton(type, page) {
            const params = {
                member_uniqid: this.profile_data?.uniqid,
                member_token: this.member_token,
                type: type,
                page: page
            }
            this.relation_is_loading = true;
            await this.getMoreRelationData(params);
            if (type == 'fans') {
                this.fans_page = this.fans_page + 1;
            } else if (type == 'follow') {
                this.follow_page = this.follow_page + 1;
            } else if (type == 'block') {
                this.block_page = this.block_page + 1;
            }
            this.relation_is_loading = false;
        },
        changeThumb() {
            this.$refs.img_upload.click();
        },
        async handleUploadImage() {
            const file = this.$refs.img_upload.files[0];
            const API_url = "https://pixnetid.pixnet.cc/avatar";
            const params = {
                pcard_id: this.profile_id,
                member_token: this.member_token,
                refresh: 1
            };
            let bodyFormData = new FormData();
            if (file.type == 'image/heic') {
                const toPng = await this.convertHeicToPng(file);
                bodyFormData.append('avatar', toPng);
            } else {
                bodyFormData.append('avatar', file)
            }
            const req = await axios({
                method: 'post',
                url: API_url,
                data: bodyFormData,
                headers: { 'Authorization': this.member_token, "Content-Type": "multipart/form-data" },
            }).catch((e) => {
                console.log(e)
            })
            if (req.status === 200 && !req.error) {
                this.getProfileData(params)
            } else {
                return Promise.reject(req);
            }
        },
        async convertHeicToPng(file) {
            const heic2any = (await import('heic2any')).default;
            return new Promise((resolve, reject) => {
                heic2any({
                    blob: file,
                    toType: 'image/jpeg',
                    quality: .5,
                }).then(async (result) => {
                    let newFile = new File(
                        [result],
                        file.name.toLowerCase().replace(/.heic$/, '.jpeg'), {
                        type: 'image/jpeg',
                        lastModified: new Date().getTime(),
                    });
                    resolve(newFile);
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        addCommasToNumber(numberInput) {
            if (numberInput) {
                return numberInput.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return numberInput;
            }
        },
        gtag() {
            window.dataLayer.push(arguments);
        },
        activeGaPage() {
            this.gtag('config', 'G-ZXCL2XMGZC', {
                'channel_category': '2024新版名片頁pcard'
            });
            this.gtag('event', 'page_view', {
                page_title: '2024新版名片頁pcard',
            });
        },
        toggleReportAndBlock() {
            this.block_menu_status = !this.block_menu_status;
        },
        paFunction() {
            window._piq.push(
                ['setCustomVar', 'venue', '1d-657ff51f89188'],
                ['setCustomVar', 'visitor_id', ''],
                ['setCustomVar', 'member_uniqid', ''],
                ['trackPageView']
            );
            window._piq.push(['trackEvent', 'rs2022::新名片頁::名片頁全頁', 'imps::頁面::page::user_action', '', '']);
        },
        paScrollFunction() {
            addEventListener("scroll", () => {
                window._piq.push(['trackEvent', 'rs2022::新名片頁::名片頁全頁', 'scroll::頁面::page::user_action', '', '']);
            }, { once: true });
        },
        paEntryBlog(url) {
            window._piq.push(['trackEvent', 'rs2022::新名片頁::名片頁區塊', 'click::部落格入口::button::newpcard', '動態::' + url.replace('https://', 'http://'), '']);
        },
        paClickFollowOrEditBtn(text) {
            window._piq.push(['trackEvent', 'rs2022::新名片頁::名片頁區塊', 'click::' + text + '::button::newpcard', '', '']);
        },
        paClickToOldPcard(url) {
            window._piq.push(['trackEvent', 'rs2022::新名片頁::名片頁區塊', 'click::切換舊名片頁按鈕::button::newpcard', '動態::' + url.replace('https://', 'http://'), '']);
        },
        paClickMenu(text) {
            window._piq.push(['trackEvent', 'rs2022::新名片頁::名片頁區塊', 'click::' + text + '::button::newpcard', '', '']);
        },
        paClickSocialMedia(text, url) {
            window._piq.push(['trackEvent', 'rs2022::新名片頁::名片頁區塊', 'click::' + text + '::button::newpcard', '動態::' + url.replace('https://', 'http://'), '']);
        },
        paClickMoreArticle() {
            window._piq.push(['trackEvent', 'rs2022::新名片頁::文章', 'click::看更多::button::newpcard', '', '']);
        },
        paClickMoreShortArticle() {
            window._piq.push(['trackEvent', 'rs2022::新名片頁::短貼文', 'click::看更多::button::newpcard', '', '']);
        },
        paClickCategoryRank(url) {
            window._piq.push(['trackEvent', 'rs2022::新名片頁::名片頁區塊', 'click::分類排行::button::newpcard', '動態::http://www.pixnet.net/' + url, '']);
        }
    },
    async fetch() {
        const params = {
            pcard_id: this.profile_id,
        };
        await this.getProfileData(params);
    },
    async mounted() {
        const params = {
            pcard_id: this.profile_id,
            member_token: this.member_token,
        };
        await this.getProfileData(params);
        if (this.profile_data) {
            this.paFunction();
            this.paScrollFunction();
            this.activeGaPage();

            const article_params = {
                member_uniqid: this.profile_data?.uniqid,
                member_token: this.member_token
            };
            const fans_params = {
                member_uniqid: this.profile_data?.uniqid,
                member_token: this.member_token,
                type: 'fans'
            };
            const follow_params = {
                member_uniqid: this.profile_data?.uniqid,
                member_token: this.member_token,
                type: 'follow'
            };
            const block_params = {
                member_uniqid: this.profile_data?.uniqid,
                member_token: this.member_token,
                type: 'block'
            }
            const short_article_param = {
                member_uniqid: this.profile_data?.uniqid,
                member_token: this.member_token,
            }
            if (this.is_user_mode && this.open_block) {
                setTimeout(() => {
                    this.openFollowerListAndActiveFanTab(3);
                }, 500)
            }

            this.initTabAll();
            this.getUserBlogCategory(this.profile_data?.uniqid);
            this.getUserArticleCount(this.profile_data?.user_name)
            this.getUserArticle(article_params);
            this.getUserShortArticle(short_article_param);
            this.getRelationData(fans_params);
            this.getRelationData(follow_params);
            this.getUserPoint(this.profile_data?.uniqid);

            if (this.is_user_mode) {
                await this.getRelationData(block_params);
            }
            this.is_follow = this.profile_data?.followed;
        }
        this.rebootPixAd();
    },
    watch: {
        pcard_fans_list_status: function () {
            if (this.pcard_fans_list_status) {
                document.body.classList.add('open_mask');
            } else {
                document.body.classList.remove('open_mask');
            }
        }
    }
}
