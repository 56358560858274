
import { mapMutations, mapActions } from 'vuex';
import axios from "axios";
export default {
    data() {
        return {
            cancel_block: false
        }
    },
    props: {
        item: Object,
        member_uniqid: String
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
    },
    methods: {
        ...mapMutations("pcard", [
            "toggleUnblockCheckBox",
            "setBlockDisplayName"
        ]),
        ...mapActions("pcard", [
            "getRelationData"
        ]),
        checkToUnblock() {
            const block_member = {
                uniqid: this.item?.uniqid,
                name: this.item?.displayname
            }
            this.setBlockDisplayName(block_member);
            this.toggleUnblockCheckBox();
        },
    },
    mounted() {
        // console.log(this.item)
    }
}
