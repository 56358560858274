
import axios from "axios";
import { mapMutations, mapActions } from 'vuex'; 
export default {
    name: "pcard-short-article-check-to-delete",
    data() {
        return {
        }
    },
    computed: {
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        set_to_delete_article_id() {
            return this.$store.state.short_article.to_delete_short_article_id;
        },
        show_box() {
            return this.$store.state.short_article.open_delete_status;
        },
        profile_data() {
            return this.$store.state.pcard.profile_data;
        },
    },
    methods: {
        ...mapMutations('short_article', [
            'toogleDeleteBoxStatus',
            'setToDeleteShortArticleId'
        ]),
        ...mapMutations('lightbox', [
            'setViewData',
        ]),
        ...mapActions('pcard', [
            'getUserShortArticle'
        ]),
        toggleShowBox() {
            this.toogleDeleteBoxStatus();
            this.setToDeleteShortArticleId('');
        },
        openToastElement(){
            this.$parent.openToast();
        },
        async deleteShortArticle() {
            const API_url = 'https://www.pixnet.net/mainpage/api/qna/PersonalArticle?member_token=' + this.member_token + '&hash_id=' + this.set_to_delete_article_id;
            const req = axios.delete(API_url);

            req.then((e) => {
                if(!e.data.error && e.status === 200) {
                    this.toggleShowBox();
                    this.setViewData("delete_correct");
                    this.openToastElement();
                    this.getUserShortArticle(this.profile_data?.uniqid);
                }
            })
            .catch((e) => {
                console.log(e)
            })
        }
    }
}
