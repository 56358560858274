
import axios from "axios";
import { mapMutations } from 'vuex';
export default {
    props: {
        block_name: String,
        block_uniqid: String
    },
    computed: {
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
    },
    methods: {
        ...mapMutations("pcard", [
            "toggleBlockCheckBox",
            "setBlockDisplayName"
        ]),
        ...mapMutations('lightbox', [
            'setViewData',
        ]),
        async blockMember() {
            try {
                const API_url = 'https://www.pixnet.net/pcard/api/block/members/' + this.block_uniqid;
                const req = await axios({
                    method: 'post',
                    url: API_url,
                    headers: { 'Authorization': this.member_token, "Content-Type": "multipart/form-data" },
                })
                if(req.status === 200 && !req.error) {
                    this.toggleBlockCheckBox();
                    this.setBlockDisplayName(this.block_name);
                    this.setViewData("block");
                    this.$parent.openToast();
                    location.reload();
                } else {
                    return Promise.reject(req);
                }
            } catch(e) {
                console.log(e)
            }

        }
    },
    mounted() {
    }
}
