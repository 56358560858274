
export default {
    computed: {
        member_data() {
            return this.$store.state.login.member_data;
        },
        member_link() {
            return '/pcard/' + this.member_data.member_name + '?open_block=1'
        }
    },
    mounted() {
    }
}
