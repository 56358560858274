
import axios from "axios";
import { mapMutations } from 'vuex';
export default {
    computed: {
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        unfollow_data() {
            return this.$store.state.pcard.unfollow_data;
        },
        is_login() {
            return this.$store.state.login.login_status;
        },
    },
    methods: {
        ...mapMutations("pcard", [
            "toggleUnfollowCheckBox"
        ]),
        async DeleteFans() {
            const API_url = "https://www.pixnet.net/mainpage/api/channel/deleteMyFan"
            let bodyFormData = new FormData();
            bodyFormData.append('member_token', this.member_token)
            bodyFormData.append('member_uniqid', this.unfollow_data.uniqid)
            const req = await axios({
                method: 'post',
                url: API_url,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            }).catch((e)=> {
                console.log(e)
            })
            if(this.is_login && this.member_token!= null) {
                if(req.status === 200 && !req.error) {
                    this.$parent.changeTabAndReloadData();
                    this.toggleUnfollowCheckBox();
                } else {
                    return Promise.reject(req);
                }
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
    },
    mounted() {
    }
}
