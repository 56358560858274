
import axios from "axios";
import { mapMutations } from 'vuex';
export default {
    name: 'pcard-follow-card',
    props: {
        item: Object,
        mode: String,
        page: Number,
        idx: Number,
        is_self: Boolean
    },
    data() {
        return {
            is_followed: this.item?.followed ?? false,
        }
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        member_uniqid() {
            return this.$store.state.login.member_data.member_uniqid;
        },
        link_to() {
            if(this.item.user_name != null && this.item.user_name != undefined &&  this.item.user_name != '') {
                return '/pcard/' + this.item.user_name
            } else {
                return '/pcard/' + this.item.uniqid
            }
        },
        mode_text() {
            if(this.mode == 'fans') {
                return '粉絲卡片'
            } else if(this.mode == 'follow') {
                return '關注者卡片'
            }
        },
        list_text() {
            if(this.mode == 'fans') {
                return '粉絲清單'
            } else if(this.mode == 'follow') {
                return '關注者清單'
            }
        },
    },
    methods: {
        ...mapMutations("pcard", [
            "toggleUnfollowCheckBox",
            "setUnfollowData"
        ]),
        async followStatus() {
            const API_url = "https://www.pixnet.net/mainpage/api/channel/setMemberRelation"
            let bodyFormData = new FormData();
            bodyFormData.append('member_token', this.member_token)
            bodyFormData.append('member_uniqid', this.item?.uniqid)
            bodyFormData.append('type', 'follow')
            bodyFormData.append('action', this.is_followed? 0 : 1)
            const req = await axios({
                method: 'post',
                url: API_url,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            }).catch((e)=> {
                console.log(e)
            })
            if(this.is_login && this.member_token!= null) {
                if(req.status === 200 && !req.error) {
                    this.is_followed = !this.is_followed;
                    // this.$parent.changeTabAndReloadData();
                } else {
                    return Promise.reject(req);
                }
            } else {
                location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=${location.href}`;
            }
        },
        DeleteFansCheck() {
            const member_data = {
                uniqid: this.item.uniqid,
                name: this.item.displayname
            }
            this.toggleUnfollowCheckBox();
            this.setUnfollowData(member_data);
        },
        paClickLink(){
            let pa_idx = (this.page + (this.idx + 1)/10000).toFixed(4);
            window._piq.push(['trackEvent', 'rs2022::新名片頁::' + this.list_text, 'click::'+ this.mode_text +'::blogger_card::newpcard', '關注有興趣的部落客::' + this.item?.displayname + '/' + this.item?.uniqid, pa_idx]);
        },
        paClickFollowStatus(text) {
            let pa_idx = (this.page + (this.idx + 1)/10000).toFixed(4);
            window._piq.push(['trackEvent', 'rs2022::新名片頁::' + this.list_text, 'follow::'+ text +'::blogger_card::newpcard', '關注有興趣的部落客::' + this.item?.displayname + '/' + this.item?.uniqid, pa_idx]);
        }
    },
    mounted() {
        this.is_followed = this.item?.followed;
    },
}
