
import { mapMutations } from 'vuex';
import { RANK_LIST } from '~/utils/user_point';
import { separateNumber } from '~/utils/number';

export default {
    props: {
        uniqid: String,
        desktop: Boolean | undefined,
    },
    data() {
        return {
            badges: RANK_LIST,
        };
    },
    computed: {
        total_point() {
            return this.$store.state.pcard.user_total_point;
        },
    },
    methods: {
        ...mapMutations("pcard", [
            "toggleUserPointIntro",
            "toggleUserPointRankDetail"
        ]),
        separateNumber,
    },
}
